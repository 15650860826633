<template>
  <div style="background: #eef4ff;">
    <div class="header"></div>
    <div class="main py-7" >
      <div class="iframe-container">
        <div class="loading-overlay" v-if="isLoading">
          <v-progress-circular 
            :size="50" 
            indeterminate 
            class="primary--text" 
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
      addressSearched: false,
      elasticId: null,
      omniScriptUrl: process.env.VUE_APP_OMNIOUT_URL,
    }
  },

  created() {
    // this has been commented for not passing the address to omniout correctly.
    // if (this.$cookies.isKey("elastic_location_id")) {
    //   this.addressSearched = true
    //   this.elasticId = this.$cookies.get('elastic_location_id')
    //   this.omniScriptUrl += `?c__PremiseID=${this.elasticId}`
    // }

    // this is an OG for redirecting users to leads-out
    window.location.href = this.omniScriptUrl;
  },

};
</script>

<style scoped>

.header {
  background: #ff008e;
  height: 100px;
}
.title {
  padding: 2rem;
  text-align: center;
}
.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>
